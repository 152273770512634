<template>
  <div class="elite-content_sidebar_level2">
    <div class="elite-content_sidebar_level2_inner">
      <h2>{{ $t("APPS.DPA_MODULE") }}</h2>
      <ul class="sub-menu-items">
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_DPAPAYMENTS)">
          <router-link
            :to="{ name: 'ListDpaTransactions' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("DPA_PPA.TRANSACTIONS") }}
              </span>
            </a>
          </router-link>
        </li>
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_DPATRANSACTIONS)">
          <router-link
            :to="{ name: 'ListDpaFiles' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("DPA_PPA.FILES") }}
              </span>
            </a>
          </router-link>
        </li>
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_DPA_ACCOUNTS)">
          <router-link
            :to="{ name: 'ListDpaAccounts' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("DPA_PPA.ACCOUNTS") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  mixins: [moduleLayoutMixin],
};
</script>
