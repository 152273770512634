export default {
  // THIRD_PARTY_SERVICES_LIST: "Liste des services tiers",
  // SERVICE_PREVIEW: "Voir un aperçu",
  // ADD_SERVICE: "Ajouter un service tier",
  // ADD_THIS_SERVICE: "Ajouter ce service tier",
  // SERVICE_ADDED: "Service tier ajouté",
  // EDIT_SERVICE: "Modifier un Service tier",
  // EDIT_THIS_SERVICE: "Modifier ce Service tier",
  // SERVICE_UPDATED: "Service tier mise à jour",
  // DELETE_THIS_SERVICE: "Supprimer ce Service tier?",
  // SERVICE_DELETED: "Service tier supprimé",
  // SERVICE_NAME: "Nom",
  // SERVICE_TYPE: "Type",
  // SERVICE_MODE: "Mode",
  // SERVICE_TEST_CONFIG: "Configurations Test",
  // SERVICE_PROD_CONFIG: "Configurations Prod",
  // RESULTS_ON_X_LINES: "Resultats pour un total de {linesCount} lignes",
  // SUCCESSES: "Succes",
  // FAILURES: "Echecs",
  // X_ROW: "Ligne {line}",
  // LINE: "Ligne",
  // DATA: "Donnee",
  // ERROR: "Erreur",
  // MODEL_ID: "ID",
  // RESULTS: "Resultats",
  // IMPORTED_ITEMS: "Objets importés",
  // ITEM: "Objet",
  // DELETE_THIS_THIRD_PARTY_SERVICE: "Supprimer ce service tiers ?",
  // THIRD_PARTY_SERVICE_DELETED: "Service tiers supprimé",


  THIRD_PARTY_SERVICES_LIST: "Liste des service tiers",
  ADD_THIRD_PARTY_SERVICE: "Ajouter une service tier",
  THIRD_PARTY_SERVICE_ADDED: "Service tiers ajoutée",
  EDIT_THIRD_PARTY_SERVICE: "Modifier une service tier",
  THIRD_PARTY_SERVICE_UPDATED: "Service tiers mise à jour",
  DELETE_THIS_THIRD_PARTY_SERVICE: "Supprimer ce service tier ?",
  THIRD_PARTY_SERVICE_DELETED: "Service tiers supprimée",
  VIEW_THIRD_PARTY_SERVICE: "Service tiers",
  SERVICE_ACTIVE: "Actif?",
    SERVICE_MODE: "Mode",
  SERVICE_TEST_CONFIG: "Configurations Test",
  SERVICE_PROD_CONFIG: "Configurations Prod",
};
